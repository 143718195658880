.name-input{
    background: 0;
    border: none;
    color: #fff;
    text-align: center;
}
.name-input:focus{
    outline:none;
    color: #fff !important;
}
.table-img-td{
    width: 100%;
}
.table-img-tag{
    /* width: 80px;
    height: 80px; */
    position: relative;
    padding: 0 !important;
}
.table-img-tag img{
    width: 100%;
    height:  50px;
}
.table-img-tag .ant-tag-close-icon{
    position: absolute;
    right: -5px;
    top: 0;
    transform: translateX(-50%);
    transform: translateY(-50%);
    color: #fff;
}
.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }
  
  .row-dragging td {
    padding: 16px;
  }
  
  .row-dragging .drag-visible {
    visibility: visible;
  }
  .upload-img-title{
    color: #7377A1;
    font-size: 0.75em;
    text-align: left;
    margin-bottom: 1.25em;
}
.upload-img-table tr{
    border-radius: 10px;
    overflow: hidden;
    font-size: 13px;
}
.upload-img-table .ant-table{
    background: none;
}
.upload-img-table thead tr th{
    background: #312E4F;
    color: #fff;
    text-align: center;
    border: none;
}
.upload-img-table .ant-table-container table>thead>tr:first-child th:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.upload-img-table .ant-table-container table>thead>tr:first-child th:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.upload-img-table .ant-table-tbody>tr>td{
    border: none;
    color: #fff;
    text-align: center;
}
.upload-img-table .ant-table-tbody>tr.ant-table-row:hover>td, .ant-table-tbody>tr>td.ant-table-cell-row-hover{
    background: unset;
}
.upload-img-table .ant-table-tbody .ant-table-row:nth-child(odd):hover{
    background: none;
}
.upload-img-table .compile-btn{
    background: url('../../../assets/btn-border.png') no-repeat;
    background-size: 100% 100%;
}
.upload-img-table .ant-btn{
    border: none;
    color: #fff;
    font-size: 13px;
}
.upload-img-table .compile-btn:hover{
    background: url('../../../assets/btn-border.png') no-repeat;
    background-size: 100% 100%;
}
.upload-img-table  .dele-btn:hover{
    background: linear-gradient(89.22deg, #FEA502 -12.76%, #E6328C 48.72%, #CA1FDC 110.19%, #3359F9 171.66%, #0BA6FC 233.14%);
}
.upload-img-table  .dele-btn{
    border-radius: 20px;
    background: linear-gradient(89.22deg, #FEA502 -12.76%, #E6328C 48.72%, #CA1FDC 110.19%, #3359F9 171.66%, #0BA6FC 233.14%);
}

.upload-img-table .ant-table-tbody .ant-table-row:nth-child(even){
    border: 0.75px solid #3D3C66;
    background: rgba(255, 255, 255, 0.1);
}
/* .upload-img-table .ant-table-tbody .ant-table-row:nth-child(even) td{
    background: rgba(255, 255, 255, 0.1);
} */
.upload-img-table .ant-table-tbody .ant-table-row:nth-child(even) td:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.upload-img-table .ant-table-tbody .ant-table-row:nth-child(even) td:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.upload-img-table .ant-table-tbody .ant-table-row:nth-child(even):hover{
    background: rgba(255, 255, 255, 0.1);
}
.upload-img-table .table-img-tag-box{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
}
.emit-imgs{
    cursor: pointer;
}
.our-num{
    background: #0E0724 !important;
    border: 1px solid #312E4F !important;
    border-radius: 1.25em !important;
    color: #fff!important;
    font-size: 0.75em !important;
}