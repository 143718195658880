.content-box .bg-btn{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
    border: none;
    width: 40%;
    color: #fff;
    font-size: 0.8125em;
    border-radius: 1.25em;
    float: right;
}
.content-box .bg-btn:hover{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%) !important;
}
.color-text{
    color: #7377A1;
    text-align: left;
}
 .my-box-ass{
    color: #fff;
}
.my-box-title-top{
    margin-bottom: 1em;
}
.my-box-title-num{
    color: #0BA6FC;
}
.my-box-title-num1{
    margin-right: 10em;
}
/* .content-box .back-btn{
    width: 16%;
    color: #fff;
    float: right;
    border-radius: 1.25em;
    font-size: 13px;
    margin-top: 1em;
    border: none;
    background: url(../../assets/my-wallet-backbtn.png) no-repeat;
    background-size: 100% 100%;
}
.content-box .back-btn:hover{
    background: url(../../assets/my-wallet-backbtn.png) no-repeat;
    background-size: 100% 100%;
} */
.content-box .my-title{
    margin-bottom: 1.5em;
}