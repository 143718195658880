.system-page-title{
    display: flex;
    align-items: center;
    color: #7377A1;
    font-size: 0.8125em;
}
.system-page-title-left{
    margin-right: 5em;
}
.system-page-title-input{
    border-radius: 1.25em;
    border: 1px solid #312E4F;
    background-color: #0E0724;
    margin-left: 0.625em;
    height: 1.875em;
}
.system-page-content{
    background: rgba(255, 255, 255, 0.1);
    border: 0.75px solid #3D3C66; 
    padding: 1.25em;  
    border-radius: 10px;
    text-align: center;
    font-size: 0.8125em;
    margin-top: 1.25em;
}
/* .system-page-content .name-input{
    margin-bottom: 1.25em;
} */
.system-page-content-chrbox{
    /* margin-top: 1.25em; */
}
.system-page-content .name-input input{
    width: 100%;
    background: #0E0724;
    border: 1px solid #312E4F;
    border-radius: 1.25em;
    height: 3em;
}
.system-page-content .system-page-content-chrbox-but button{
    font-size: 0.8125em;
    width: 70%;
}
.system-page-content-totle{
    margin-bottom: 1.5em;
}
.system-page-content .add-table-btn{
    font-size: 0.8125em;
    width: 12%;
}
.system-page-content-line{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
    height: 1px;
    width: 100%;
    margin: 1.25em 0;
}
.isok-table-btn{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%) !important;
    border: none !important;
    color: #fff !important;
    font-size: 0.8125em !important;
    width: 15%;
}
.system-page-content-chrbox-but .ant-tag{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0;
    margin-bottom: 1.25em;
    border: none;
}
.system-page-content-chrbox-but .ant-tag .ant-image{
    width: 100%;
    height: 5em;
}
.system-page-content-chrbox-but .ant-tag .ant-image img{
    width: 100%;
    height: 100%;
}
.system-page-content-chrbox-but .ant-tag .ant-tag-close-icon{
    color: white;
    position: absolute;
    right: -5px;
    top: 0;
    transform: translateY(-50%);
}
.system-page-content-chrbox-none{
    display: none;
}
.fileInput{
    display: none;
}
.is-del{
    display: none !important;
}
.system-page-content input{
    text-align: center;
}