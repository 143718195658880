.pageproducer-box{
    padding: 2em;
    background: rgba(255, 255, 255, 0.1);
    border: 0.75px solid #3D3C66;
}
.pageproducer-box .pageproducer-img img{
    width: 100%;
}
.pageproducer-box .form-item-box{
    margin-bottom: 3em !important;
}
.pageproducer-box .ant-form-item-label{
    width: 21%;
}
.pageproducer-box .ant-form-item-label>label{ 
    color: #fff !important;
}
.pageproducer-box .form-item-input {
    background: #0E0724 !important;
    border: 2px solid #312E4F;
    border-radius: 1.25em;
}
.add-img-btn{
    display: flex;
    justify-content: right;
}
.pageproducer-box .add-img{
    width: 22%;
    margin-top: 5px;
    font-size: 0.8125em;
    margin-bottom: 1.25em;
}
.pageproducer-box .form-item-btn button{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
    border: none;
    color: #fff;
    font-size: 0.8125em;
    width: 25%;
    float: right;
}