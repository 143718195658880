/* .generate-work{
    height: 82vh;
} */
.generate-work-title{
    margin-top: 2vh;
    height: 2vh;
    text-align: left;
    font-size: 14px;
    color: #7377A1;
}
.generate-work-box .ant-transfer{
    width: 72vw;
}
.generate-work-box .ant-transfer-operation{
    width: 2vw;
    margin: 4px 0.5vw;
}
.generate-work-imgbox{
    height: 72vh;
    margin-top: 2vh;
    background: #1b1530;
}
.checkAll{
    float: right;
    margin: 2vh 1em 0 0;
}
.generate-work-imgbox .ant-checkbox-wrapper{
    color: #fff;
}
.generate-work-box{
    margin-top:5vh;
}
.generate-work-box .ant-transfer-list{
    width: 34.5vw;
    height: 73vh;
    border-color: #312E4F;
}
.generate-work-box .ant-transfer-list-content-item{
    width: 6.3vw;
}
.generate-work-box .ant-transfer-list-content-item .ant-checkbox-wrapper{
    position: absolute;
    left: 2px;
    top: 2px;
    z-index: 999;
}
.generate-work-box  .ant-transfer-list-content{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    margin-left: 2%;
    margin-top: 5px
}
.generate-work-box  .ant-image-img{
    height: 6.3vw;
}
.generate-work-box .ant-transfer-list-content-item{
    padding: 0;
    margin: 1px;
    /* border: 1px solid #fff; */
    position: relative;
    /* vertical-align: top; */
}
.generate-work-box .ant-transfer-list-content-item-text img{
    vertical-align: bottom;
}
.generate-work-box  .ant-transfer-list-header{
    background: #312E4F;
    color: #fff;
    border-color: #312E4F;
}
.generate-work-box .ant-transfer-operation .ant-btn-icon-only.ant-btn-sm{
    background: #312E4F;
    color: #fff;
    border: none;
}