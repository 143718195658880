.navBar{
    background-color: #1b1530;
    color: #FFFFFF;
    padding: 0 1.875em;
    font-size: 0.875em;
    height: 10vh;
}
.navBar-row{
    height: 10vh;
}
.border-bom{
    height: 1px;
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
}
.logo{
    width: 60%;
}
.nav-box{
    margin-top: 0.3125em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav-box li{
    height: 1.25em;
}
.logo-btn{
    background: url('../assets/button-bacimg.png') no-repeat;
    background-size: 100% 100%;
    border: none;
    margin-right: 1.25em;
    color: #FFFFFF;
    width: 7.5em;
    height: 1.875em;
    font-size: 0.75em;
}
.wallet-btn{
    background: url('../assets/button-wallet.png') no-repeat;
    background-size: 100% 100%;
    border: none;
    color: #FFFFFF;
    width: 7.5em;
    height: 1.875em;
    font-size: 0.75em;
}
.border-line{
    margin-top: 0.3125em;
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
    height: 1px;
    width: 100%;
}
.nav-active{
    border-bottom: 2px solid;
    border-image: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%) 2 2 2 2;
}
.nav-a{
    color: #FFFFFF;
}
.content-box{
    padding: 2vh 14vw;
    background-color: #0E0724;
    color: #fff;
    min-height: 90vh;
    text-align: center;
}
.content-box .bg-btn{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
    border: none;
    width: 40%;
    color: #fff;
    font-size: 0.8125em;
    border-radius: 1.25em;
    float: right;
}
.content-box .bg-btn:hover{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%) !important;
}
.content-box .back-btn{
    width: 16%;
    color: #fff;
    float: right;
    border-radius: 1.25em;
    font-size: 13px;
    margin-top: 1em;
    border: none;
    background: url(../assets/my-wallet-backbtn.png) no-repeat;
    background-size: 100% 100%;
}
.content-box .back-btn:hover{
    background: url(../assets/my-wallet-backbtn.png) no-repeat;
    background-size: 100% 100%;
}
html body {
    min-width: 69rem;
}
