.case-component{
    background: rgba(255, 255, 255, 0.1);
    border: 0.75px solid #3D3C66;
    text-align: center;
    padding: 1em;
    color: #fff;
    width: 30%;
    border-radius: 10px;
}
.case-component-title{
    font-size: 1.125em;
    margin-bottom: 0.5em;
}
.case-component-content{
    padding-bottom: 1em;
    border-bottom:  0.86px solid #CCCCCC;
    font-size: 13px;
}
.case-component-money{
    text-align: right;
    color: #CA1FDC;
    margin: 1em 0.5em 1em 0;
}