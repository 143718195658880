.expenses-case-title-txt{
    color: #fff;
    font-size: 13px;
}
.expenses-case-title-input .ant-select-selector{
    background: #0E0724 !important;
    border: 2px solid #312E4F !important;
    border-radius: 1.25em !important;
    color: #fff !important;
    margin-left: 2em;
    width: 20vw !important;
    font-size: 0.8125em;
}
.expenses-case-title-box{
    margin-top: 3em;
    margin-bottom: 2em;
}
.expenses-case-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.expenses-case-content-chrd{
    width: 28%;
}
.expenses-case-title .ant-select-arrow{
    color: #fff;
}