.personal-data-box{
    background: rgba(255, 255, 255, 0.1);
    border: 0.75px solid #3D3C66;
    padding: 3em;
}
.personal-data-box .personal-data-img img{
    width: 100%;
}
.personal-data-box .personal-data-from .ant-form-item-label>label{
    color: #7377A1;;
    font-size: 1em;
}
.personal-data-box .personal-data-from-input{
    background: #0E0724;
    border: 2px solid #312E4F;
    border-radius: 1.25em;
    margin-left: 1em;
}
.personal-data-box .personal-data-from-input:focus{
    color: #fff;
}
.personal-data-box .personal-data-from-input:valid{
    color: #fff;
}
.personal-data-box .personal-data-from-content{
    color: #fff;
    text-align: left;
    margin-left: 3em;
}
.personal-data-box .personal-data-from-btn{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
    border: none;
    width: 40%;
    color: #fff;
    font-size: 0.8125em;
    border-radius: 1.25em;
    float: right;
}
.personal-data-box .personal-data-from-btn:hover, .personal-data-box .personal-data-from-btn:focus{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
}
.personal-data-box .ant-form-item{
    margin-bottom: 20px !important;
}
.personal-data-from-btn-box{
    margin-bottom: 0 !important;
}