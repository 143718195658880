.white-list-box .add-white-box{
    display: flex;
    justify-content: right;
}
.white-list-box .add-white-btn{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%) !important;
    border: none !important;
    width: 15%;
    color: #fff !important;
    font-size: 0.8125em;
    margin-bottom: 1.5em;
}
.usestate{
    color: #E6328C;
}
.fromadress-box .ant-space-item:first-child{
    width: 85%;
}
.index-box-sort{
    width: 20%;
}