.SuccessCase-title{
    color: #fff;
    text-align: center;
    font-size: 1.25em;
    margin-bottom: 2.5em;
 }
 .SuccessCase-content{
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    align-content:space-between;
    /* height: 38vw; */
 }
 .SuccessCase-box{
    margin-bottom: 1.875em;
 }
 .SuccessCase-content li{
    width: 17vw;
    position: relative;
    margin-right: 1vw;
    /* background: url(../../assets/success1.png) no-repeat; */
    /* background-size: 100% 100%; */
    height: 18vw;
    margin-bottom: 20px;
 }
 /* .SuccessCase-content li img{
    width: 100%;
 } */
 .SuccessCase-content-name{
    position: absolute;
    bottom: 0;
    color: #7377A1;
    width: 100%;
    height: 3.125em;
    background: linear-gradient(180deg, rgba(38, 32, 58, 0.2) 0%, #26203A 100%);
    display: flex;
    align-items: center;
    padding-left: 1.25em;
    font-size: 0.875em;
 }
 .case-card-img{
    width: 100%;
    height: 100%;
 }
 .case-card-img img{
    width: 100%;
    height: 100%;
 }
 .case-card-name{
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #fff;
 }
 .case-card-name-time{
    font-weight: normal;
    font-size: 0.75em;
 }
 .case-card-name-box{
    justify-content: space-between;
 }
 .case-card-delete-btn{
    font-size: 10px !important;
    /* border: solid 1px #ff4d4f !important; */
 }