.register-box{
    padding: 6.25em 14vw;
    background-color: #0E0724;
    color: #fff;
    text-align: center;
}
.NFT-img{
    width: 30vw;
}
.register-text{
    font-size: 1.25em;
    margin-bottom: 0.3125em;
}
.from-box{
    background: rgba(255, 255, 255, 0.1);
    border: 0.75px solid #7377A1;
    width: 30vw;
    border-radius: 0.9375em;
    padding: 2.5em 1.5625em;
}
.from-box .ant-input, .from-box .ant-select-selection-item{
    font-size: 0.75em;
}
.from-box .ant-input-group-addon:first-child{
    border: none;
    margin-left: 0.625em;
    background: #0E0724;
    border-radius: 1.25em;
    margin-top: 0.3125em;
    width: 2.1875em;
}
.user-icon{
    width: 100%;
}
.userInput .ant-input{
    background: #0E0724;
    width: 100%;
    border: none;
    border-radius: 1.25em;
    padding: 0.25em 0;
}
.userInput .ant-input-wrapper{
    background: #0E0724;
    border: 2px solid #312E4F;
    border-radius: 1.25em;
    padding-top:0.3125em;
    color: #fff !important;
}
.userInput-img{
    position: absolute;
    left: 0.3125em;
}
.userInput .ant-input:focus {/* 去除获取焦点时的样式 */
    border: none !important;
    box-shadow: none;
    color: #fff !important;
}
.userInput .ant-input:valid{
    color: #fff;
    background-color: #0E0724;
    border-radius: 1.25em;
}
.userInput .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
    background-color: #0E0724 !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 1.25em;
}
.register-type .ant-form-item-label>label{
    color: #fff;
    margin: 0 0.9375em;
}
/* .register-type  .ant-form-item-control-input{
    background: #0E0724;
    color: #fff;
} */
.register-type .ant-select {
    border-radius: 1.25em;
}
.register-type .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: #0E0724;
    border-radius: 1.25em;
    border: 2px solid #312E4F;
    color: #fff;
}
.register-type .ant-select-arrow .anticon>svg{
    color: #fff;
}
.from-line{
    height: 1px;
    width: 100%;
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
    margin-bottom: 0.9375em;
}
.ant-form-item{
    margin-bottom: 19px !important;
}
.from-box  .ant-form-item-control-input-content{
    display: flex;
    justify-content: space-between;
}
.from-box  .ant-form-item-control-input-content button{
    width: 45%;
}
.from-box  .ant-form-item-control-input-content .goBack-btn{
    width: 100%;
    height: 100%;
    border-radius: 1.25em;
    border: none;
    background-color:  #0E0724;
    color: #fff;
    font-size: 0.75em;
}

.goBack-btn-box{
    width: 45%;
    border-radius: 1.25em;
    padding: 1px;
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
}
.from-box .submit-btn{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
    border: none;
    font-size: 12px;
    border-radius: 20px;
    color: #fff;
}
.from-box .submit-btn:hover{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
}
.from-box .submit-btn:focus {
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
    color: #fff;
}
.getyanzhengma{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
    border-radius: 1.25em;
    width: 30% !important;
    height: 100%;
    font-size: 0.625em;
    color: #fff;
    position: absolute;
    right: 0;
    z-index: 999;
}
/* .yanzhengma-input{
    padding-right: 25%;
} */
.from-box .ant-form-item-explain-error{
    font-size: 10px;
    text-align: left;
    padding-left: 30px;
}
.userTypecompany{
    display: none;
}