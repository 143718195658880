.navmenu-box{
    font-size: 13px;
    /* margin-bottom: 30px; */
}
.navmenu-box a{
    color:#fff ;
    height:  1.5em;
    display: inline-block;
}
.navmenu-box div.active, .navmenu-box a.active{
    height: 1.5em;
    border-bottom: solid 1px;
    border-image: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%) 2;
}
.case-lists .navmenu-box{
    justify-content: space-evenly;
    margin-bottom: 5em;
    /* margin-top: 2em; */
}
.case-lists .navmenu-box a{
    width: 100%;
    font-size: 15px;
}
.navmenu-top{
    height: 2vh;
}