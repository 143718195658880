.material-title{
    color: #7377A1;
    font-size: 12px;
    text-align: left;
    margin-bottom: 10px;
}
.material-content-box{
    background: rgba(255, 255, 255, 0.1);
    border: 0.75px solid #3D3C66;
    padding-left:40px;
}
.material-img img{
    width: 100%;
}
.material-content-box .ant-form-item-label>label{
    color: #fff;
    font-size: 13px;
}
.material-content-box .ant-input,.material-content-box .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background: #0E0724;
    border: 2px solid #312E4F;
    color: #fff;
    border-radius: 20px;
    margin-left: 10px;
}
.material-content-box .ant-select-arrow{
    color: #fff !important;
}
.flex-box{
    display: flex;
    justify-content: space-between;
}
.flex-box .ant-form-item{
    width: 46%;
}
.isradio-box .ant-radio-wrapper{
    color: #fff;
}
.isradio-box  .ant-radio-inner{
    background: #0E0724;
    border: 2px solid; 
    border-color: #312E4F !important;
}
.isradio-box .ant-radio-inner:after{
    background: #312E4F;
}
.isradio-box .dataPicker-box{
    background: #0E0724;
    border: 2px solid #312E4F;
    border-radius: 20px;
    width: 100%;
}
.dataPicker-box .ant-picker-suffix{
    color: #fff;
}
.dataPicker-box .ant-picker-input>input{
    color: #fff;
    margin-left: 10px;
}
.dataPicker-box .ant-picker-input{
    flex-direction: row-reverse;
}
.dataPicker-box .ant-picker-suffix img{
    width: 60%;
}
.isradio-box-left{
    width: 35%;
}
.isradio-box-right{
    flex: 1;
}
.material-content-box .ant-form-item-control-input-content{
    display: flex;
    justify-content: space-around;
}
.material-content-box .submit-btn{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
    border: none;
    width: 35%;
    height: 35px;
    border-radius: 20px;
    color: white;
    margin-top: 10px;
}
.material-content-box .ant-btn:hover, .material-content-box .ant-btn:focus {
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
    color: white;
}
.material-content-box .ant-form-item{
    margin-bottom: 20px !important;
}
.material-content-box .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,.material-content-box  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover{
    background: #0E0724;
    border: 2px solid #312E4F;
}
.material-content-box .ant-form-item-explain-error{
    text-align: left;
    font-size: 12px;
    margin-left: 10px;
}
.material-content-box .ant-picker-status-error.ant-picker, .ant-picker-status-error.ant-picker:not([disabled]):hover{
    background: #0E0724;
    border: 2px solid #312E4F;
}