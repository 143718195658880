.my-account-box .my-account-more{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
    border: none;
    width: 15%;
    color: #fff;
    font-size: 0.8125em;
    border-radius: 1.25em;
    margin-top: 1em;
    margin-right: 2em;
}
.my-account-box .my-account-more:hover{
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
}
.my-account-btn-box{
    display: flex;
    justify-content: right;
}