.middle-box{
    padding: 3.125em 7vw;
    background-color: #0E0724;
}
.ant-carousel{
    width: 86vw;
    height: 34vw;
}
.ant-carousel img{
    width: 100%;
    height: 100%;
}
.ant-carousel .slick-dots li button{
    width: 0.625em !important;
    height: 0.625em !important;
    border-radius: 10px!important;
    border: solid 2px #7377A1!important;
    background-color: #0E0724!important;
}
.ant-carousel .slick-dots li.slick-active{
    width: 16px!important;
}
.ant-carousel .slick-dots li.slick-active button{
    background: #7377A1!important;
}
.ant-carousel .slick-dots-bottom{
    bottom: -1.25em!important;
}
.IssueProblem-box{
    margin: 4.0625em 0;
    color: #fff;
    text-align: center;
}
.IssueProblem-box .title{
    font-size: 1.25em;
    margin-bottom: 1.25em;
}
.IssueProblem-nav-box{
    background: rgba(115, 119, 161, 0.15);
    padding: 3.125em;
    border-radius: 5px;
}
.IssueProblem-nav-title{
    margin-bottom: 0.9375em; 
}
.IssueProblem-nav-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.IssueProblem-nav-list li{
    position: relative;
    width: 15%;
    height: 4.0625em;
    padding: 2px;
    border-radius: 3.125em 3.125em 3.125em 0;
    background-image: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
    /* z-index: 2; */
}
.IssueProblem-nav-list-li-div{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 0.875em;
    border-radius: 6.25em 6.25em 6.25em 0;
    background-color: #0E0724;
 }
 .IssueProblem-nav-list-li-div .top{
    margin-bottom: 5px;
 }
 .IssueProblem-nav-content{
    margin: 1.875em 0;
 }
 .IssueProblem-nav-mes{
    color: #7377A1;
    font-size: 0.5em;
 }
 .TechnicalFeature-box{
    margin-top: 1.875em;
    text-align: center;
    color: #fff;
    margin-bottom: 6.25em;
 }
 .TechnicalFeature-title{
    margin-bottom: 1.875em;
 }
 .TechnicalFeature-content{
    display: flex;
    justify-content: space-around;
    padding: 0 6.25em;
 }
 .TechnicalFeature-content-chirdbox{
    width: 20%;
 }
 .TechnicalFeature-content-chirdbox-top{
    width: 100%;
    height: 4.0625em;
    font-size: 1.25em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
    border-radius: 3.125em;
    margin-bottom: 1.25em;
 }
 .TechnicalFeature-content-chirdbox-mes{
    font-size: 0.875em;
    color: #7377A1;
 }
 .TechnicalFeature-content-chirdbox-mes .top{
    margin-bottom: 5px;
 }
 .index-SuccessCase-title{
    color: #fff;
    text-align: center;
    font-size: 1.25em;
    margin-bottom: 2.5em;
 }
 .index-SuccessCase-content{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content:space-between;
    height: 38vw;
 }
 .index-SuccessCase-box{
    margin-bottom: 1.875em;
 }
 .index-SuccessCase-content li{
    width: 18vw;
    position: relative;
    background: url(../../assets/success1.png) no-repeat;
    background-size: 100% 100%;
    height: 18vw;
 }
 .index-SuccessCase-content li img{
    width: 100%;
 }
 .index-SuccessCase-content-name{
    position: absolute;
    bottom: 0;
    color: #7377A1;
    width: 100%;
    height: 3.125em;
    background: linear-gradient(180deg, rgba(38, 32, 58, 0.2) 0%, #26203A 100%);
    display: flex;
    align-items: center;
    padding-left: 1.25em;
    font-size: 0.875em;
 }
 .footer-box{
   color: #fff;
   border-top: solid 1px;
   padding: 2.1875em 7vw;
   border-image: linear-gradient(90deg, #FEA502 0%, #E6328C 25%, #CA1FDC 50%, #3359F9 75%, #0BA6FC 100%);
   background-color: #0E0724;
 }
 .footer-left-logo{
   width: 50%;
   margin-bottom: 0.625em;
 }
 .footer-left-text{
   font-size: 0.75em;
 }
 .icon-box{
   display: inline-block;
   width: 2vw;
   height: 2vw;
 }
 .icon-box img{
   width: 100%;
   height: 100%;
 }
 .footer-right-bom{
   margin-top: 0.625em;
   font-size: 0.625em;
   text-align: right;
 }